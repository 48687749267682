import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackendBilling } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IActFile, IActsList } from "@/store/acts/interfaces";
import { IPagination } from "@/interfaces";

@Module({ dynamic: true, store, name: "Act" })
class ActStore extends VuexModule {
  private _actsList = {} as IActsList;
  private _actFile = {} as IActFile;

  @Mutation
  public SET_ACTS_LIST(value: IActsList): void {
    this._actsList = value;
  }

  @Mutation
  public SET_ACT_FILE(value: IActFile): void {
    this._actFile = value;
  }

  @Action({ rawError: true })
  public async getActFile(options: {
    id: string
    params: {
      base64: number
    }
    
  }) {
    const response: AxiosResponse<IActFile> = await instanceBackendBilling.get(
      `acts/download/${options.id}`, { params: options.params }
    );
    
    this.SET_ACT_FILE(response?.data);
    return response?.data; 
  }

  @Action({ rawError: true })
  public async getActsList(options?: IPagination) {
    const response: AxiosResponse<IActsList> = await instanceBackendBilling.get(
      "acts", { params: options }
    );
    this.SET_ACTS_LIST(response.data);
  }
 
  get actsList() {
    return this._actsList;
  }

  get actFile() {
    return this._actFile;
  }
}

export default getModule(ActStore);
