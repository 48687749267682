import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, { "tab-position": _ctx.position }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, {
        label: "ПРЕИМУЩЕСТВА",
        name: "advantages"
      }),
      _createVNode(_component_el_tab_pane, {
        label: "ТАРИФЫ",
        name: "rates"
      })
    ]),
    _: 1
  }, 8, ["tab-position"]))
}