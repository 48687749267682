
import { Vue } from 'vue-class-component';
import BillStore from '@/store/bill/index';

export default class OpenBillFile extends Vue {
  screenWidth = window.screen.width
  screenHeight = window.screen.height

  mounted() {
    this.updateWidth()
    this.getBillFile()
  }

  async getBillFile() {
    await BillStore.getBillFile({
      id: this.$route.params.id as string,
      params: {
        base64: 1
      }
    });
  }

  created() {
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth() {
    this.screenWidth = window.innerWidth - 100;
    this.screenHeight = window.innerHeight - 100;
  }

  get billFile() {
    return BillStore.billFile.file;
  }
}
