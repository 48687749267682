
import { Options, Vue } from 'vue-class-component';
import NotificationStore from "@/store/notification";
import { ENotificationContext, ENotificationTypes } from '@/store/notification/interfaces';
import router from '@/router';


@Options({
  components: {},
})
export default class CriticalNotifications extends Vue {

  readNotification(notificationIds: string[]) {
    setTimeout(async () => {
      await NotificationStore.notificationsRead({
        notificationIds: notificationIds
      }),
      this.getNotificationsListUnreadAlert(),
      this.getNotificationsUnread()
    }, 500);
  }

  async getNotificationsListUnreadAlert() {
    await NotificationStore.getNotificationList({
      page: 1,
        limit: 100,
        isRead: false,
        type: ENotificationTypes.ALERT
    })
  }

  async getNotificationsUnread() {
    await NotificationStore.getNotificationList({
      page: 1,
      limit: 10,
      isRead: false,
    }) 
  }

  get notificationsListUnreadAlert() {
    return NotificationStore.notificationsListUnreadAlert;
  }

  redirectToPay() {
    this.readNotification(this.notificationsListUnreadAlert.data
      .filter((notification) => notification.context == ENotificationContext.PAYMENT)
      .map((notification) => notification.externId)
    )
    router.push('/payment')
  }

}
