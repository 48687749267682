
import { Vue } from 'vue-class-component';
import { Prop, Watch } from "vue-property-decorator";
import { IAccount } from "@/store/auth/interfaces";
import FinanceStore from "@/store/finance/index";
import AuthStore from "@/store/auth/index";

export default class AddFundsModal extends Vue {

  show = false;

  params = {
    amount: 0,
    accountId: -1
  }

  open() {
    this.show = true;
  }

  async handleOpen() {
    this.init();
  }

  async init() {
    this.params.accountId = this.account.id ? this.account.id : -1;
  }

  async addFunds() {
    await FinanceStore.addFunds(this.params);
    await FinanceStore.getBalance();
    this.show = false;
  }

  get account() {
    return AuthStore.userInfo && AuthStore.userInfo.account ? AuthStore.userInfo.account : {} as IAccount;
  }

}
