
import { Vue } from 'vue-class-component';
import { Prop, Watch } from "vue-property-decorator";
import { ILicense } from "@/store/license/interfaces";
import LicenseStore from "@/store/license/index";
import RootStore from "@/store/root/index";

export default class LicenseInfoModal extends Vue {

  @Prop() licenseId!: string;

  show = false;

  form = {
    id: "",
    name: "",
    licenseId: "",
    activeFrom: "",
    activeTo: "",
    status: "",
    accountId: -1,
    tariffId: "",
    createdAt: "",
    updatedAt: "",
    tariff: {
        id: "",
        description: "",
        productId: "",
        cost: -1,
        duration: -1,
        feePeriodType: "",
        isActive: false,
        serviceBindedData: null,
        createdAt: "",
        updatedAt: ""
    }
  } as unknown as ILicense;

  open() {
    this.show = true;
  }

  async handleOpen() {
    this.init();
  }

  async init() {
      await LicenseStore.getLicense(this.licenseId);
      this.form = { ...this.form, ...this.license };
  }

  get license() {
    return LicenseStore.license;
  }

  get dictionary() {
    return RootStore.dictionaries;
  }

}
