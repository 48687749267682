
import { Options, Vue } from 'vue-class-component';
import { ElNotification } from 'element-plus';
import FinanceStore from '@/store/finance/index';
import BillStore from '@/store/bill/index';
import { EPaymentSystem } from '@/store/finance/interfaces';
import PayQrModal from '@/views/payment/modals/PayQrModal.vue';
import { validateNumber } from "@/utils/helpers";
import LicenseStore from "@/store/license/index";
import NotificationStore from "@/store/notification";
import { socket } from "@/socket";

const defaultPaymentParams = JSON.stringify({
    paymentSystem: EPaymentSystem.PSB as EPaymentSystem | 'Bill',
    imageQR: '',
    uuid: '',
    paymentLink: '',
    amount: ''
});

@Options({
    components: { PayQrModal }
})

export default class Payment extends Vue {
    selectedPeriod = 1
    activeNames = ''
    arrayFundedLicenses: any = []

    loading = false;

    paymentParams = JSON.parse(defaultPaymentParams)

    mounted() {
        this.init();
    }

    async init() {
        this.loading = true;
        this.paymentParams = JSON.parse(defaultPaymentParams);
        this.changePeriod();
        this.updateBalance();
        this.loading = false;
    }

    validate() {
        this.paymentParams.amount = validateNumber(this.paymentParams.amount.toString());
    }

    async getLicenseList() {
        await LicenseStore.getLicensesList({
            page: 1,
            limit: 1000,
        });
    }

    async pay() {
        const isSBP = FinanceStore.supportsSBP.includes(this.paymentParams.paymentSystem);

        if(!this.paymentParams.amount || this.paymentParams.amount === 0) {
            ElNotification({
                title: 'Ошибка',
                message: 'Отсутствует сумма пополнения',
                type: 'error',
            })
        } else {
            this.loading = true;

            if(this.paymentParams.paymentSystem === 'Bill') {
                const createBill = await BillStore.createBill(this.paymentParams.amount)
                if(createBill) this.loading = false;
                return
            }

            const paymentData = await FinanceStore.pay({
                paymentSystem: this.paymentParams.paymentSystem,
                price: Number(this.paymentParams.amount),
                isSBP: isSBP
            })

            if(!paymentData) {
                ElNotification({
                    title: 'Ошибка',
                    message: 'Выбранный вариант недоступен, попробуйте другой способ',
                    type: 'error',
                })
            } 
            else {
                this.paymentParams.imageQR = paymentData.qr;
                this.paymentParams.paymentLink = paymentData.url;
                this.paymentParams.uuid = paymentData.uuid; 
                !isSBP ? this.redirectToPaypage() : this.showPaymentQR();
            }

            this.loading = false;
        }
    }

    async changePeriod() {
        await FinanceStore.getTransactionSum({
            amount: this.selectedPeriod,
            periodType: 'month'
        });
        this.getLicenseList()
        this.arrayFundedLicenses = []
        
        Object.keys(this.transactionsSum.fundedLicenses || {}).map((key) => this.arrayFundedLicenses.push({
            id: key,
            sum: this.transactionsSum.fundedLicenses[key]
        }))
        
        this.paymentParams.amount = this.transactionsSum.recommendedSum;
    }

    returnLicenseName(id: string) {
        return this.licenseList?.find(license => license.id === id)?.name || "Неизвестно"
    }

    async updateBalance() {
        await FinanceStore.getBalance();
    }

    redirectToPaypage() {
        window.open(this.paymentParams.paymentLink, '_blank');
    }

    showPaymentQR() {
        const modal: any = this.$refs["PayQrModal"];
        modal.open();
    }

    get balance() {
        return FinanceStore.balance;
    }

    get transactionsSum() {
        return FinanceStore.transactionsSum;
    }

    get licenseList() {
        return LicenseStore.licenseList.data;
    }

    async createNotify() {
        await NotificationStore.createNotifyForSocket([{
            type: "ALERT",
            text: "Тест тест тест",
            externId: this.uuidv4(),
            scope: ["WEB"],
            context: "PAYMENT",
            accountId: 1
        }])
    }

    socketIsConnect() {
        console.log(socket)
    }

    openSocket() {
        socket.open()
    }

    closeSocket() {
        socket.close()
    }

    uuidv4() {
        return (([1e7]as any)+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, (c: any) =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
}
