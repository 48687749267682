import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackendBilling, instanceBackendPaybox } from "@/plugins/axios";
import { 
  EPaymentSystem,
  IBalance, 
  IPay, 
  IPaymentStatus, 
  IPaymentStatusResponse, 
  ITransactionList,
  ITransactionSum} from "@/store/finance/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISort } from "@/interfaces";

@Module({ dynamic: true, store, name: "Finance" })
class FinanceStore extends VuexModule {
  private _balance = {} as IBalance;
  private _transactionsList = {} as ITransactionList;
  private _supportsSBP = [EPaymentSystem.PSB] as EPaymentSystem[];
  private _transactionsSum = {} as ITransactionSum;

  @Mutation
  public SET_BALANCE(value: IBalance): void {
    this._balance = value;
  }

  @Mutation
  public SET_TRANSACTIONS_LIST(value: ITransactionList): void {
    this._transactionsList = value;
  }

  @Mutation
  public SET_TRANSACTIONS_SUM(value: ITransactionSum): void {
    this._transactionsSum = value;
  }

  @Action({ rawError: true })
  public async getBalance() {
    const response: AxiosResponse<IBalance> = await instanceBackendBilling.get(
      "/balance"
    );
    
    this.SET_BALANCE(response?.data);
  }

  @Action({ rawError: true })
  public async getTransactionsList(options?:
    IPagination &
    ISort
    ) {
    const response: AxiosResponse<ITransactionList> = await instanceBackendBilling.get(
      `/transactions`, { params: options });
    this.SET_TRANSACTIONS_LIST(response.data);
  }

  @Action({ rawError: true })
  public async addFunds(params: { accountId: number, amount: number }) {
    const response: AxiosResponse<IBalance> = await instanceBackendBilling.put(
      `/balance/${params.accountId}/add`, { amount: params.amount });
    
    this.SET_BALANCE(response.data);
  }

  @Action({ rawError: true })
  public async getTransactionSum(options: {
    amount: number,
    periodType: string
  }) {
    const response: AxiosResponse<ITransactionSum> =
      await instanceBackendBilling.get(`/balance/transactionSum`, { params: options });

      this.SET_TRANSACTIONS_SUM(response.data);
    return response; 
  }

  @Action({ rawError: true })
  public async pay(params: {
    paymentSystem: EPaymentSystem,
    price: number,
    isSBP: boolean
  }) {

    const response: AxiosResponse<IPay> = await instanceBackendBilling.post(
      `/balance/add/${params.paymentSystem}`, { price: params.price, isSBP: params.isSBP });
    
      if(!response) return false
      
    return response.data;
  }

  @Action({ rawError: true })
  public async getPaymentStatus(uuid: string) {
    const response: AxiosResponse <IPaymentStatusResponse> = await instanceBackendPaybox.get(`/receipt/status/${uuid}`);

    return response.data.status;
  }

  get balance() {
    return this._balance;
  }

  get transactionsList() {
    return this._transactionsList;
  }

  get supportsSBP() {
    return this._supportsSBP;
  }

  get transactionsSum() {
    return this._transactionsSum;
  }

  get paymentStatuses() : IPaymentStatus[] {

    const paymentStatuses: IPaymentStatus[] = [];
    const successCodes = [ 0, 10, 11 ];

    successCodes.forEach(successCode => {
      paymentStatuses.push({
        code: successCode,
        description: 'Оплата прошла успешно!',
        type: 'success'
      })
    });

    return [
      ...paymentStatuses,
      {
        code: 211,
        description: 'Ожидание оплаты',
        type: 'warning'
      },
      {
        code: 201,
        description: 'Ошибка при оплате',
        type: 'danger'
      }
    ]
  }

}

export default getModule(FinanceStore);
