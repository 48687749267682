import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackendBilling } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISort } from "@/interfaces";
import { ENotificationTypes, INotificationsList } from "./interfaces";

@Module({ dynamic: true, store, name: "Notification" })
class NotificationStore extends VuexModule {
  private _notificationsList = {} as INotificationsList;
  private _notificationsListRead = {} as INotificationsList;
  private _notificationsListUnread = {} as INotificationsList;
  private _notificationsListUnreadAlert = {} as INotificationsList;

  @Mutation
  public SET_NOTIFICATIONS_LIST(value: INotificationsList): void {
    this._notificationsList = value;
  }

  @Mutation
  public SET_NOTIFICATIONS_LIST_READ(value: INotificationsList): void {
    this._notificationsListRead = value;
  }

  @Mutation
  public SET_NOTIFICATIONS_LIST_UNREAD(value: INotificationsList): void {
    this._notificationsListUnread = value;
  }

  @Mutation
  public SET_NOTIFICATIONS_LIST_UNREAD_ALERT(value: INotificationsList): void {
    this._notificationsListUnreadAlert = value;
  }

  @Action({ rawError: true })
  public async getNotificationList(options?: 
    IPagination & 
    ISort & 
    {
      isRead?: boolean,
      type?: ENotificationTypes
    }
  ) {
    const response: AxiosResponse<INotificationsList> = await instanceBackendBilling.get(
      "notifications", { params: options }
    );
    
    if(options?.type == ENotificationTypes.ALERT)
      this.SET_NOTIFICATIONS_LIST_UNREAD_ALERT(response.data);
    else {
      if(options?.isRead === true)
        this.SET_NOTIFICATIONS_LIST_READ(response.data);
      else if(options?.isRead === false)  
        this.SET_NOTIFICATIONS_LIST_UNREAD(response.data);
      else  
        this.SET_NOTIFICATIONS_LIST(response.data);
    }  
  }

  @Action({ rawError: true })
  public async notificationsRead(options: {
    notificationIds: string[]
  } 
  ) {
    const response: AxiosResponse<INotificationsList> = await instanceBackendBilling.post(
      "notifications/readByExtId", options
    )
  }

  @Action({ rawError: true })
  public async createNotifyForSocket(options: {
    type: string,
    text: string,
    externId: string,
    scope: string[],
    context: string,
    accountId: number
  }[] 
  ) {
    const response: any = await instanceBackendBilling.post(
      "/", options
    )
  }
 
  get notificationsList() {
    return this._notificationsList;
  }

  get notificationsListRead() {
    return this._notificationsListRead;
  }

  get notificationsListUnread() {
    return this._notificationsListUnread;
  }

  get notificationsListUnreadAlert() {
    return this._notificationsListUnreadAlert;
  }

}

export default getModule(NotificationStore);
