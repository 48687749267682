
import { Options, Vue } from 'vue-class-component';
import AuthStore from "@/store/auth";
import FinanceStore from "@/store/finance";
import { User } from '@element-plus/icons';
import AddFundsModal from "@/views/balance/modals/AddFundsModal.vue";
import Bell from "@/components/Bell.vue";
import router from "@/router";

@Options({
  components: { User, AddFundsModal, Bell },
})
export default class Menu extends Vue {

  balanceAccessRoles = ["SUPER_ADMIN", "ACCOUNT_OWNER"];

  redirectToPay() {
    router.push('/payment')
  }

  get isAuth() {
    return AuthStore.loggedIn;
  }

  async login() {
    await AuthStore.login();
  }

  async logout() {
    await AuthStore.logout();
  }

  get initials() {
    return this.user ? `${this.user.lastName[0]}${this.user.firstName[0]}` : ""
  }

  get userName(): string {
    return this.user ? `${this.user.lastName} ${this.user.firstName} ${this.user.middleName ? this.user.middleName : ''}` : "Неизвестно";
  }

  get user() {
    return AuthStore.userInfo.user
  }

  get accountName(): string {
    const account = Object.keys(AuthStore.userInfo).length
      ? AuthStore.userInfo.account
      : null;

    return account ? account.name : "Неизвестно";
  }

  get isBalanceAccess() {
    const roles = Object.keys(AuthStore.userInfo).length
      ? AuthStore.userInfo.roles
      : null;

    return this.balanceAccessRoles.some((role) => roles?.includes(role));
  }

  get balance() {
    return FinanceStore.balance;
  }
}
