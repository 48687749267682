
import { Vue } from 'vue-class-component';
import { Prop, Watch } from "vue-property-decorator";
import { ElNotification } from 'element-plus';
import { IAccount } from "@/store/auth/interfaces";
import FinanceStore from "@/store/finance/index";
import AuthStore from "@/store/auth/index";

export default class PayQrModal extends Vue {

  @Prop() uuid!: string;
  @Prop() paymentLink!: string;
  @Prop() imageQR!: string;

  paymentStatus = 211;

  loading = false;

  intervalId = -1;

  show = false;

  open() {
    this.paymentStatus = 211;
    this.intervalId = this.createFetchInterval();
    this.show = true;
  }

  createFetchInterval() {
    return window.setInterval(this.getPaymentStatus, 5000);
  }

  destroyFetchInterval() {
    return clearInterval(this.intervalId);
  }

  async getPaymentStatus() {
    this.loading = true;
    this.paymentStatus = await FinanceStore.getPaymentStatus(this.uuid);
    this.loading = false;

    if( ( this.paymentStatus === 11) || ( this.paymentStatus === 10) || ( this.paymentStatus === 0)  ) {
      this.destroyFetchInterval();
      ElNotification({
        title: 'Успех',
        message: 'Оплата прошла успешно',
        type: 'success',
      })
    }
  }

  redirectToPaypage() {
    window.open(this.paymentLink, '_blank');
  }

}
