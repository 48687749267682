import { IPaginationResponse, ISortDirection } from "@/interfaces";
import { EService } from "@/store/root/interfaces";
import { ITariff } from "@/store/tariff/interfaces"

export interface ILicenseListResponse {
  data: ILicense[];
  pagination: IPaginationResponse;
}

export interface ILicense {
  service?: EService;
  accountId: number;
  activeFrom: string;
  activeTo: string;
  comment?: string;
  createdAt: string;
  deletedAt?: string | null;
  id: string;
  licenseId: string;
  name: string;
  secret?: string | null;
  status: string | undefined;
  updatedAt: string;
  tariff: ITariff;
}

export enum ELicenseStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  EXPIRED = "expired",
  PAUSED = "paused"
}

export interface ILicenseStatus {
  eng: ELicenseStatus;
  name: string;
  type: string;
}

export interface ILicenseListSort {
  activeTo: ISortDirection,
  name: ISortDirection
}
