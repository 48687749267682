
import { Vue } from 'vue-class-component';
import ActStore from '@/store/acts/index';

export default class OpenActFile extends Vue {
  screenWidth = window.screen.width
  screenHeight = window.screen.height

  mounted() {
    this.updateWidth()
    this.getActFile()
  }

  async getActFile() {
    await ActStore.getActFile({
      id: this.$route.params.id as string,
      params: {
        base64: 1
      }
    });
  }

  created() {
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth() {
    this.screenWidth = window.innerWidth - 100;
    this.screenHeight = window.innerHeight - 100;
  }

  get actFile() {
    return ActStore.actFile;
  }
}
