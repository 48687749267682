import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "row g-0 vh-100 flex-nowrap" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = {
  key: 1,
  class: "p-3 scroll"
}
const _hoisted_6 = {
  key: 2,
  class: "content p-3 scroll"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CriticalNotifications = _resolveComponent("CriticalNotifications")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isAuth)
          ? (_openBlock(), _createBlock(_component_Sidebar, {
              key: 0,
              collapse: _ctx.collapse
            }, null, 8, ["collapse"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isAuth)
          ? (_openBlock(), _createBlock(_component_Menu, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.isAuth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_router_view)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAuth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.notificationsListUnreadAlert.data?.length)
                ? (_openBlock(), _createBlock(_component_CriticalNotifications, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(_component_router_view)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}