import AuthStore from "@/store/auth";
import config from "@/config";
import axios from "axios";
import qs from "qs";
import { ElNotification } from "element-plus";
import router from "@/router";
import { useCookies } from "vue3-cookies"; 
const { cookies } = useCookies();

export const instanceLayer = axios.create({
  baseURL: config.layer.host,
  paramsSerializer,
});

export const instanceAms = axios.create({
  baseURL: config.ams.host,
  paramsSerializer,
});

export const instanceBackendPaybox = axios.create({
  baseURL: config.paybox.host,
  paramsSerializer,
});

export const instanceBackendBilling = axios.create({
  baseURL: config.billing.host,
  paramsSerializer,
});

export const instanceDadata = axios.create({
  baseURL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/",
  paramsSerializer,
});

instanceAms.interceptors.request.use(requestHandler);
instanceAms.interceptors.response.use(responseHandler, errorHandler);

instanceBackendPaybox.interceptors.request.use(requestHandler);
instanceBackendPaybox.interceptors.response.use(responseHandler, errorHandler);

instanceBackendBilling.interceptors.request.use(requestHandler);
instanceBackendBilling.interceptors.response.use(responseHandler, errorHandler);

instanceDadata.interceptors.request.use(requestHandlerDadata);
instanceDadata.interceptors.response.use(responseHandler, errorHandler);

function paramsSerializer(params: any) {
  return qs.stringify(params, { arrayFormat: "repeat" });
}

function requestHandler(request: any) {
  request.headers = {
    authorization: `Bearer ${cookies.get("accessToken")}`,
  };
  return request;
}

function requestHandlerDadata(request: any) {
  request.headers = {
    authorization: `Token 3d7c7bcc08095e12ff98e56f226993287182b310`,
  };
  return request;
}

function responseHandler(response: any) {
  if (response.data && response.data.message) {
    ElNotification.success({
      title: "Успешно",
      message: response.data.message,
    });
  }

  return response;
}

function errorHandler(error: any) {
  const {
    response: { status },
  } = error;

  const message =
    error.response &&
    error.response.data &&
    (error.response.data.message || error.response.data.description);

  if (status === 401) {
    AuthStore.LOGOUT();
    router.push("/");
  } else {
    ElNotification.error({
      title: "Ошибка",
      message: message || "Неизвестная ошибка",
    });
  }
}
