import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("object", null, [
    _createElementVNode("embed", {
      type: "text/html",
      width: _ctx.screenWidth,
      height: _ctx.screenHeight,
      src: `data:application/pdf;base64, ${_ctx.actFile}`
    }, null, 8, _hoisted_1)
  ]))
}