import { IPaginationResponse, ISortDirection } from "@/interfaces"

export interface IBalance {
  id: string,
  currentValue: string,
  accountId: number,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null
}

export interface IPay {
    id: number,
    url: string,
    uuid: string,
    qr: string
}

export interface IPaymentStatusResponse {
  status: number
}

export interface IPaymentStatus {
  code: number,
  description: string,
  type: string
}

export interface ITransactionList extends IPaginationResponse {
 data: ITransaction[]
}

export interface ITransaction {
  id: number,
  description: string,
  amount: string,
  extInfo: string | null,
  accountId: number,
  systemEventId: number,
  createdAt: string,
  updatedAt: string
}

export interface ITransactionSum {
  fundedLicenses: any,
  recommendedSum: number
}

export interface ISystemEventsListSort {
  createdAt: ISortDirection,
}

export interface ITransactionsListSort {
  createdAt: ISortDirection
}

export enum EPaymentSystem {
  YOOKASSA = "Yookassa",
  TWOCAN = "Twocan",
  SBERBANK = "Sberbank",
  UNITELLER = "Uniteller",
  PSB = "PSB"
}