import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceAms, instanceBackendBilling, instanceBackendPaybox } from "@/plugins/axios";
import { IRoleTranslates, IDictionaryResponse, IDictionaries } from "@/store/root/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import AuthStore from "@/store/auth";
import LicenseStore from "@/store/license/index";
import FinanceStore from "@/store/finance/index";
import AgreementStore from "@/store/agreement/index";
import NotificationStore from "@/store/notification/index";
import { ENotificationTypes } from "@/store/notification/interfaces";

@Module({ dynamic: true, store, name: "Root" })
class Root extends VuexModule {
  private _roleTranslates = {} as IRoleTranslates;
  private _dictionaries = {} as IDictionaries;
  private _initState = false;
  private _access = true;
  private _allowedRoles = ["SUPER_ADMIN", "ADMIN", "SUPPORT", "ACCOUNT_OWNER", "ACCOUNT_MEMBER"];

  @Mutation
  public SET_ROLE_TRANSLATES(data: IRoleTranslates): void {
    this._roleTranslates = data;
  }

  @Mutation
  public SET_DICTIONARIES(params: {
    field: keyof IDictionaries;
    keyId?: string;
    data: IDictionaries[keyof IDictionaries]["list"];
  }): void {
    if (!params.keyId) params.keyId = "eng";

    if (!this._dictionaries[params.field])
      this._dictionaries[params.field] = { list: [], map: {} };

    this._dictionaries[params.field].list = params.data;

    const map = {} as any;

    for (let index = 0; index < params.data.length; index++) {
      const element = params.data[index] as any;

      if (!map[element[params.keyId]]) map[element[params.keyId]] = element;
    }

    this._dictionaries[params.field].map = map;
  }

  @Mutation
  public SET_INIT_STATE(value: boolean): void {
    this._initState = value;
  }

  @Mutation
  public SET_ACCESS(value: boolean): void {
    this._access = value;
  }

  @Action({ rawError: true })
  public async fetchRoleTranslates() {
    const response: AxiosResponse<IRoleTranslates> = await instanceAms.get(
      "/roles/translates"
    );

    if (response && response.data) {
      this.SET_ROLE_TRANSLATES(response.data);
    }
  }

  @Action({ rawError: true })
  public async fetchDictionary() {
    const response: AxiosResponse<IDictionaryResponse> = await instanceBackendBilling.get(
      "/dictionary"
    );

    const payDictionary: AxiosResponse<any> = await instanceBackendPaybox.get(
      "/info/pay"
    );

    this.SET_DICTIONARIES({
      data: payDictionary?.data?.result?.entity,
      keyId: "id",
      field: "entity" as keyof IDictionaries,
    })
      
    if(response && response.data) {
      Object.entries(response.data).forEach(([key, value]) => {
        return this.SET_DICTIONARIES({
          data: value,
          keyId: "eng",
          field: key as keyof IDictionaries,
        });
      });
    }
  }

  @Action({ rawError: true })
  public async initApp() {
    let access = true;

    await AuthStore.login();

    const myRoles = AuthStore.userInfo && AuthStore.userInfo.roles ? AuthStore.userInfo.roles : [];
    const myRights = AuthStore.userInfo && AuthStore.userInfo.rights ? AuthStore.userInfo.rights : [];

    const check = this._allowedRoles.some((role) => myRoles.includes(role));

    if (!check) access = false;

    this.SET_ACCESS(access);

    await Promise.all([
      this.fetchRoleTranslates(),
      this.fetchDictionary(),
      FinanceStore.getBalance(),
      NotificationStore.getNotificationList({
        page: 1,
        limit: 100,
        isRead: false,
        type: ENotificationTypes.ALERT
      })
    ]);


    this.SET_DICTIONARIES({
      data: LicenseStore.licenseStatuses,
      keyId: "eng",
      field: "licenseStatuses",
    });

    this.SET_DICTIONARIES({
      data: FinanceStore.paymentStatuses,
      keyId: "code",
      field: "paymentStatuses",
    });

    this.SET_DICTIONARIES({
      data: AgreementStore.agreementStatuses,
      keyId: "eng",
      field: "agreementStatuses",
    });

    this.SET_INIT_STATE(true);
  }

  get roleTranslates(): IRoleTranslates {
    return {
      SUPER_ADMIN: { key: "SUPER_ADMIN", name: "Главный администратор" },
      SUPPORT: { key: "SUPPORT", name: "Техническая поддержка" },
      ADMIN: { key: "ADMIN", name: "Администратор" },
      ...this._roleTranslates,
    };
  }

  get initState() {
    return this._initState;
  }

  get access() {
    return this._access;
  }

  get dictionaries() {
    return this._dictionaries;
  }

}

export default getModule(Root);
