import { IPaginationResponse } from "@/interfaces"

export interface INotificationsList extends IPaginationResponse {
  data: INotification[];
}

export interface INotification {
  accountId: number,
  createdAt: string,
  deletedAt: string | null,
  externId: string,
  isRead: boolean,
  text: string,
  systemEventId: any,
  updatedAt: string,
  type: ENotificationTypes,
  context: ENotificationContext,
  scope: string[],
}

export enum ENotificationTypes {
  ALERT = "ALERT",
  MESSAGE = "MESSAGE",
}

export enum ENotificationContext {
  PAYMENT = "PAYMENT",
  FN = "FN",
}
