
import { Options, Vue } from 'vue-class-component';
import {
    RefreshRight,
    Setting,
    View,
    Refresh,
    Download,
} from '@element-plus/icons';
import ActStore from '@/store/acts/index';
import { IAct } from '@/store/acts/interfaces';

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  dates: [],
});

@Options({
    components: { 
      RefreshRight, 
      Setting, 
      View, 
      Refresh,
      Download,
    }
})

export default class Act extends Vue {
  loading = false;
  showFilters = false;
  filters = JSON.parse(defaultFilters);
  loadingPdfBtn = false;
  isActFile = false
  selectedAct = {} as IAct

  mounted() {
    this.init();
  }

  async init() {
    this.getActsList();
  }

  async getActsList() {
    this.loading = true;
    await ActStore.getActsList(this.filters);
    this.loading = false;
    this.showFilters = false;
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getActsList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getActsList();
  }

  async getActFile(act: IAct) {
    this.selectedAct = act;
    this.loadingPdfBtn = true;
    this.isActFile = false
    const actFile = await ActStore.getActFile({
      id: act.id,
      params: {
        base64: 1
      }
    });
    this.isActFile = actFile ? true : false
    this.loadingPdfBtn = false;
  }

  async downloadActFile(act: IAct) {
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:application/pdf;base64,${this.actFile}`;
    downloadLink.download = `Act_${act.number}.pdf`;
    downloadLink.click();
  }

  openActFile(act: IAct){
    window.open(window.location.href + '/open/' + act.id, '_blank');
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getActsList();
  }

  get actsList() {
    return ActStore.actsList;
  }

  get actFile() {
    return ActStore.actFile;
  }
}
