import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackendBilling, instanceBackendPaybox } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import { EAgreementStatus, IAgreement, IAgreementFile, IAgreementStatus, IOrganizationListResponse } from "@/store/agreement/interfaces";
import { IPagination, ISort } from "@/interfaces";

@Module({ dynamic: true, store, name: "Agreement" })
class AgreementStore extends VuexModule {
  private _orgList = {} as IOrganizationListResponse;
  private _agreementList = {} as IAgreement;
  private _agreementFile = {} as IAgreementFile;

  @Mutation
  public SET_ORGLIST(value: IOrganizationListResponse): void {
    this._orgList = value;
  }

  @Mutation
  public SET_AGREEMENT_LIST(value: IAgreement): void {
    this._agreementList = value;
  }

  @Mutation
  public SET_AGREEMENT_FILE(value: IAgreementFile): void {
    this._agreementFile = value;
  }

  @Action({ rawError: true })
  public async getOrgList() {
    const response: AxiosResponse<IOrganizationListResponse> = await instanceBackendPaybox.post(
      "org/list", { page: 1, limit: 50 }
    );

    this.SET_ORGLIST(response.data);
    return response.data.result;
  }

  @Action({ rawError: true })
  public async getAgreementFile(id: string) {
    const response: AxiosResponse<IAgreementFile> = await instanceBackendBilling.get(
      `agreement/${id}/file`
    );
    
    this.SET_AGREEMENT_FILE(response?.data);
    return response?.data; 
  }

  @Action({ rawError: true })
  public async createAgreement(options: {
    orgId: number;
    innerOperationRequired: boolean;
  } ) {
    const response: AxiosResponse = await instanceBackendBilling.post(
      "agreement", options
    );

    return response
  }

  @Action({ rawError: true })
  public async getAgreementList(options?: IPagination & ISort) {
    const response: AxiosResponse<IAgreement> = await instanceBackendBilling.get(
      "agreement", { params: options }
    );
    this.SET_AGREEMENT_LIST(response.data);
  }

  @Action({ rawError: true })
  public async deleteAgreement(id: string) {
    const response: AxiosResponse = await instanceBackendBilling.delete(
      `agreement/${id}`
    );
  }
 
  get orgList() {
    return this._orgList;
  }

  get agreementList() {
    return this._agreementList;
  }

  get agreementFile() {
    return this._agreementFile;
  }

  get agreementStatuses(): IAgreementStatus[] {
    return [
      {
        eng: EAgreementStatus.NEW,
        name: "Новый",
        type: ""
      },
      {
        eng: EAgreementStatus.WAITING,
        name: "В ожидании",
        type: "warning"
      },
      {
        eng: EAgreementStatus.DONE,
        name: "Заключен",
        type: "success"
      },
      {
        eng: EAgreementStatus.INACTIVE,
        name: "Неактивен",
        type: "danger"
      },
    ];
  }

}

export default getModule(AgreementStore);
