import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import VdBillingPage from '@/views/vdBilling/index.vue'
import LicensesPage from '@/views/licenses/index.vue'
import TransactionsPage from '@/views/documents/transactions/index.vue'
import AgreementPage from '@/views/documents/agreement/index.vue'
import OpenAgreementPage from '@/views/documents/agreement/modals/OpenAgreementFile.vue'
import BillPage from '@/views/documents/bill/index.vue'
import OpenBillPage from '@/views/documents/bill/modals/OpenBillFile.vue'
import PaymentPage from '@/views/payment/index.vue'
import ActsPage from '@/views/documents/acts/index.vue'
import OpenActPage from '@/views/documents/acts/modals/OpenActFile.vue'
import { ElNotification } from "element-plus"
import { TokenStorage } from '@/store/tokenStorage'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'vdBilling',
    component: VdBillingPage
  },
  {
    path: '/licenses',
    name: 'licenses',
    component: LicensesPage
  },
  {
    path: '/payment',
    name: 'payment',
    component: PaymentPage
  },
  {
    path: '/documents/transactions',
    name: 'transactions',
    component: TransactionsPage
  },
  {
    path: '/documents/agreements',
    name: 'agreement',
    component: AgreementPage
  },
  {
    path: '/documents/agreements/open/:id',
    name: 'openAgreement',
    component: OpenAgreementPage
  },
  {
    path: '/documents/bill',
    name: 'bill',
    component: BillPage
  },
  {
    path: '/documents/bill/open/:id',
    name: 'openBill',
    component: OpenBillPage
  },
  {
    path: '/documents/acts',
    name: 'act',
    component: ActsPage
  },
  {
    path: '/documents/acts/open/:id',
    name: 'openAct',
    component: OpenActPage
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/views/organizations/index.vue')
  },
  {
    path: '/organization/add',
    name: 'organizationAdd',
    component: () => import('@/views/organizations/forms/addForm.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!TokenStorage.getAccessToken && to.path !== "/") {
    ElNotification.error({
      title: "Доступ запрещен",
      message: "Нужно авторизоваться",
    });
    router.push("/");
  }

  next();
});

export default router
