
import { Options, Vue } from 'vue-class-component';
import { ref } from 'vue'
import {
    RefreshRight,
    Setting,
    View
} from '@element-plus/icons';
import BillStore from '@/store/bill/index';
import { IBill, IBillPayments } from '@/store/bill/interfaces';

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  dates: [],
});

const defaultSorts = JSON.stringify(
  'createdAt-desc'
);

@Options({
    components: { RefreshRight, Setting, View, }
})

export default class Agreement extends Vue {
  loading = false;
  loadingPdfBtn = false;
  isBillFile = false
  showFilters = false;
  selectedBill = {} as IBill

  additionalColumns = {
    payments: true
  }

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);
  
  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  mounted() {
    this.init();
  }

  async init() {
    this.getBillList();
  }

  async getBillList() {
    this.loading = true;
    await BillStore.getBillList(this.filters);
    this.loading = false;
    this.closeFilters();
  }

  async getBillFile(bill: IBill) {
    this.selectedBill = bill;
    this.loadingPdfBtn = true;
    this.isBillFile = false
    const billFile = await BillStore.getBillFile({
      id: bill.id,
      params: {
        base64: 1
      }
    })
    this.isBillFile = billFile ? true : false
    this.loadingPdfBtn = false;
  }

  async downloadBillFile(bill: IBill) {
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:application/pdf;base64,${this.billFile}`;
    downloadLink.download = `Bill_${bill.number}.pdf`;
    downloadLink.click();
  }

  async openBillFile(bill: IBill){
    window.open(window.location.href + '/open/' + bill.id, '_blank');
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getBillList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getBillList();
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getBillList();
  }

  sumPayments(arrayPayments: IBillPayments[]) {
    return arrayPayments?.reduce((acc, curr) => acc + Number(curr.amount) , 0).toFixed(2)
  }

  get billList() {
    return BillStore.billList;
  }

  get billFile() {
    return BillStore.billFile.file;
  }
}
