
import { Options, Vue } from 'vue-class-component';
import { ref } from 'vue'
import { ElMessageBox, ElNotification } from 'element-plus';
import {
    RefreshRight,
    Setting,
    View,
    CopyDocument,
    Refresh,
    DocumentDelete,
    Download,
} from '@element-plus/icons';
import AgreementStore from '@/store/agreement/index';
import filters from '@/filters';
import { IAgreement } from '@/store/agreement/interfaces';
import CreateAgreementModal from '@/views/documents/agreement/modals/CreateAgreementModal.vue';
import OpenAgreementFile from '@/views/documents/agreement/modals/OpenAgreementFile.vue';

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  dates: [],
});

const defaultSorts = JSON.stringify(
  'createdAt-desc'
);

@Options({
    components: { 
      CreateAgreementModal, 
      RefreshRight, 
      Setting, 
      View, 
      OpenAgreementFile,
      CopyDocument,
      Refresh,
      DocumentDelete,
      Download,
    }
})

export default class Agreement extends Vue {
  loading = false;
  loadingPdfBtn = false;
  isAgreementFile = false
  showFilters = false;

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);
  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  additionalColumns = {
    innerOperationRequired: false
  }

  selectedAgreement = {} as IAgreement

  mounted() {
    this.init();
  }

  async init() {
    await AgreementStore.getOrgList();
    this.getAgreementList();
  }

  async getAgreementList() {
    this.loading = true;
    await AgreementStore.getAgreementList(this.filters);
    this.loading = false;
    this.closeFilters();
  }

  async getAgreementFile(agreement: IAgreement) {
    this.selectedAgreement = agreement;
    this.loadingPdfBtn = true;
    this.isAgreementFile = false
    const agreementFile = await AgreementStore.getAgreementFile(agreement.id);
    this.isAgreementFile = agreementFile ? true : false
    this.loadingPdfBtn = false;
  }

  async downloadAgreementFile(agreement: IAgreement) {
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:application/pdf,${encodeURI(this.agreementFile as any)}`;
    downloadLink.download = `Agreement_${agreement.number}.pdf`;
    downloadLink.click();
  }

  async openAgreementFile(agreement: IAgreement){
    window.open(window.location.href + '/open/' + agreement.id, '_blank');
  }

  async handleActions(event: any) {
    this.selectedAgreement = event.row;

    if (event.name === "deleteAgreement") {
      ElMessageBox.confirm(
        `Вы действительно хотите расторгнуть договор организации ${ this.getOrgName(this.selectedAgreement.orgId)} от ${ filters.formatDate(this.selectedAgreement.createdAt, "DD-MM-YYYY") } ?`,
        "Расторжение договора",
        {
          confirmButtonText: "Расторгнуть",
          cancelButtonText: "Отмена",
          center: true,
          confirmButtonClass: "el-button--danger",
          type: "error",
        }
      ).then(async () => {
        await AgreementStore.deleteAgreement(this.selectedAgreement.id)
        await this.getAgreementList()
      })
    }

    if (event.name === "copyСode") {
      navigator.clipboard.writeText(this.selectedAgreement.id)
      ElNotification.success({
        title: "Успешно",
        message: "Код синхронизации скопирован",
      })
    }
  }

  showCreateAgreement() {
    const modal: any = this.$refs["CreateAgreementModal"];
    modal.open();
  }

  getOrgName(id: number) {
    return this.orgList.result.data.find(org => org.id === id)?.name ? this.orgList.result.data.find(org => org.id === id)?.name : '-'
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getAgreementList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getAgreementList();
  }
  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getAgreementList();
  }

  get agreementList() {
    return AgreementStore.agreementList;
  }

  get orgList() {
    return AgreementStore.orgList;
  }

  get agreementFile() {
    return AgreementStore.agreementFile;
  }
}
