
import { Vue } from 'vue-class-component';
import { ElNotification } from 'element-plus';
import AgreementStore from '@/store/agreement/index';

export default class CreateAgreementModal extends Vue {

  loading = false;
  innerOperationRequired = true

  show = false;

  selectedOrgId = null;

  async open() {
    this.loading = false;
    this.show = true;
    await AgreementStore.getOrgList();    
  }

  async createAgreement() {
    if(!this.selectedOrgId) {
        ElNotification({
            title: 'Ошибка',
            message: 'Организация не выбрана',
            type: 'error',
        })
    } else {
      this.loading = true
      AgreementStore.createAgreement({
        orgId: this.selectedOrgId as number,
        innerOperationRequired: this.innerOperationRequired,
      });
      this.show = false;
    }
  }

  get orgList() {
    return AgreementStore.orgList.result;
  }
}
