
import { Options, Vue } from 'vue-class-component';
import Menu from "@/components/Menu.vue";
import CriticalNotifications from "@/components/CriticalNotifications.vue";
import Sidebar from "@/components/Sidebar.vue"
import AuthStore from "@/store/auth";
import RootStore from "@/store/root";
import NotificationStore from "@/store/notification";

@Options({
  components: { Menu, Sidebar, CriticalNotifications },
})
export default class App extends Vue {
  public collapse = true;
  
  async mounted() {
    if (!RootStore.initState && RootStore.access) {
      await RootStore.initApp();
    }
  }  

  get isAuth() {
    return AuthStore.loggedIn;
  }

  get notificationsListUnreadAlert() {
    return NotificationStore.notificationsListUnreadAlert;
  }
}
