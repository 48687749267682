import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackendBilling } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import { 
  ILicenseListResponse,
  ELicenseStatus, 
  ILicenseStatus,
  ILicense
} from "@/store/license/interfaces";
import { IPagination, ISort } from "@/interfaces";
import { EService } from "@/store/root/interfaces";

@Module({ dynamic: true, store, name: "License" })
class LicenseStore extends VuexModule {
  public _licenseList: ILicenseListResponse = {} as ILicenseListResponse;
  public _license: ILicense = {} as ILicense;

  @Mutation
  public SET_LICENSE_LIST(data: ILicenseListResponse): void {
    this._licenseList = data;
  }

  @Mutation
  public SET_LICENSE(data: ILicense): void {
    this._license = data;
  }

  @Action({ rawError: true })
  public async getLicensesList(options?:
    IPagination &
    ISort &
    { service?: EService[], status?: ELicenseStatus[], dates?: string[] }
  ) {
    const response: AxiosResponse<ILicenseListResponse> =
      await instanceBackendBilling.get(`/licenses`, { params: options });

    this.SET_LICENSE_LIST(response.data);

    return response;
  }

  @Action({ rawError: true })
  public async getLicense(licenseId: string) {
    const response: AxiosResponse<ILicense> =
      await instanceBackendBilling.get(`/licenses/${licenseId}`);

    this.SET_LICENSE(response.data);

    return response;
  }

  @Action({ rawError: true })
  public async updateLicense(options: {
    licenseId: string;
    body: Partial<ILicense>;
  }) {
    const response: AxiosResponse<ILicenseListResponse> =
      await instanceBackendBilling.patch(
        `/licenses/${options.licenseId}`,
        options.body
      );

    this.SET_LICENSE_LIST(response.data);

    return response;
  }

  @Action({ rawError: true })
  public async prolongateLicense(licenseId: string) {
    const response = await instanceBackendBilling.post(`/licenses/${licenseId}/prolongate`);

    return response
  }

  @Action({ rawError: true })
  public async deactivateLicense(licenseId: string) {
    const response = await instanceBackendBilling.post(`/licenses/${licenseId}/deactivate`);

    return response
  }

  @Action({ rawError: true })
  public async pauseLicense(licenseId: string) {
    const response = await instanceBackendBilling.post(`/licenses/${licenseId}/pause`);

    return response
  }

  get licenseList() {
    return this._licenseList;
  }

  get license() {
    return this._license;
  }

  get licenseStatuses(): ILicenseStatus[] {
    return [
      {
        eng: ELicenseStatus.ACTIVE,
        name: "Активна",
        type: "success"
      },
      {
        eng: ELicenseStatus.INACTIVE,
        name: "Неактивна",
        type: "info"
      },
      {
        eng: ELicenseStatus.EXPIRED,
        name: "Истекла",
        type: "danger"
      },
      {
        eng: ELicenseStatus.PAUSED,
        name: "Приостановлена",
        type: "warning"
      },
    ];
  }
}

export default getModule(LicenseStore);
