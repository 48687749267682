
import { Options, Vue } from 'vue-class-component';
import { 
  Expand,
  Fold,
  Postcard,
  Document,
  Coin,
  Right,
} from '@element-plus/icons'
import AuthStore from "@/store/auth";
import { IService } from '@/store/auth/interfaces';

@Options({
  components: { Expand, Fold, Postcard, Document, Coin, Right, },
  props: {
    collapse: Boolean,
  }
})
export default class Sidebar extends Vue {
  collapse = true
  servicesUserList = [] as IService[]
  clientIds = ['layer.paybox-id', 'layer.transport-id']

  mounted() {
    this.init()
  }

  async init() {
    await AuthStore.getServicesMe()
    await AuthStore.getServices()
    this.servicesUserList = this.services.filter(service => (this.clientIds.includes(service.clientId)) && (this.servicesUser.includes(service.id) || this.servicesUser[0] === null))
  }

  get servicesUser() {
    return AuthStore.userServicesInfo;
  }

  get services() {
    return AuthStore.servicesList;
  }

  redirectLink(link: string) {
    window.open(link + '/api/auth/login', '_blank');
  }
}
