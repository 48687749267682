
import { Options, Vue } from 'vue-class-component';
import {
    RefreshRight,
} from '@element-plus/icons';
import FinanceStore from '@/store/finance/index';
import { ITransactionsListSort } from '@/store/finance/interfaces';
import { ISortDirection } from '@/interfaces'
import { ref } from 'vue';

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  dates: [],
});

const defaultSorts = JSON.stringify(
  'createdAt-desc'
);

@Options({
    components: { RefreshRight }
})

export default class Transactions extends Vue {
  loading = false;
  showFilters = false;

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  cashIn = 0
  cashOut = 0

  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  additionalColumns = {
    createdAt: false
  }

  mounted() {
    this.init();
  }

  async init() {
    this.loading = true;
    this.cashIn = 0
    this.cashOut = 0
    await this.getTransactionsList()
    this.initCash()
    this.loading = false;
  }

  async getTransactionsList() {
    await FinanceStore.getTransactionsList({
      ...this.filters,
      sort: this.sorts,
    });
    this.closeFilters();
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.init();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.init();
  }

  async changeSort(e: {
    column: string;
    prop: keyof ITransactionsListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";
    const sortMap: {
      [key: string]: keyof ITransactionsListSort
    } = {
      createdAt: "createdAt",
    };
    if (!sortMap[e.prop]) return;
    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }
    this.init();
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.init();
  }

  initCash() {
    this.transactionsList.data?.map((transaction) => {
      if(parseFloat(transaction.amount) > 0 ) {
        this.cashIn += parseFloat(transaction.amount)
        this.cashIn = Math.round(this.cashIn * 100) / 100
      } else {
        this.cashOut += parseFloat(transaction.amount)
        this.cashOut = Math.round(this.cashOut * 100) / 100
      }
    })
  }

  get transactionsList() {
    return FinanceStore.transactionsList;
  }

}
