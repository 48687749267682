
import { Options, Vue } from 'vue-class-component';
import { ElMessageBox } from 'element-plus';
import LicenseStore from "@/store/license/index";
import LicenseInfoModal from "@/views/licenses/modals/LicenseInfoModal.vue";
import RootStore from "@/store/root/index";
import FinanceStore from "@/store/finance/index";
import { ILicense, ILicenseListSort } from "@/store/license/interfaces";
import { ISortDirection } from '@/interfaces'
import {
    RefreshRight,
    Setting,
    View
} from '@element-plus/icons';
import moment from "moment";
import { ref } from 'vue';

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  dates: [],
  service: [],
  status: []
});

const defaultSorts = JSON.stringify(
  'createdAt-desc'
);

@Options({
    components: {
        RefreshRight,
        Setting,
        View,
        LicenseInfoModal
    },
})

export default class Licenses extends Vue {

  selectedLicense = {} as ILicense;

  showFilters = false;
  loading = false;

  additionalColumns = {
    licenseId: false
  }

  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  mounted() {
    this.init();
  }

  async init() {
    await this.getLicenseList()
  }

  async getLicenseList() {
    this.loading = true;
    await LicenseStore.getLicensesList({
      page: this.filters.page,
      limit: this.filters.limit,
      sort: this.sorts,
      service: this.filters.service,
      status: this.filters.status,
      dates: this.filters.dates
    });
    this.loading = false;
    this.closeFilters();
  }

  async getLicense() {
    this.loading = true;
    await LicenseStore.getLicense(this.selectedLicense.id);
    this.loading = false;
  }

  async handleActions(event: any) {
    this.selectedLicense = event.row
    if (event.name === 'ShowLicenseInfo') {
      const modal: any = this.$refs["LicenseInfoModal"];
      modal.open();
    }
  }

  async pauseLicense(license: ILicense) { //приостановить
    ElMessageBox.confirm(
      `Вы действительно хотите приостановить лицензию "${license.name}"?`,
      "Приостановление лицензии",
      {
        confirmButtonText: "Приостановить",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--warning",
        type: "warning",
      }
    ).then(async () => {
      await LicenseStore.pauseLicense(license.licenseId)
      this.getLicenseList()
    })
  }

  async prolongateLicense(license: ILicense) { // продлить
    ElMessageBox.confirm(
      `Вы действительно хотите продлить лицензию "${license.name}"?`,
      "Продление лицензии",
      {
        confirmButtonText: "Продлить",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--success",
        type: "success",
      }
    ).then(async () => {
      await LicenseStore.prolongateLicense(license.licenseId) 
      this.getLicenseList()
    })
  }

  async deactivateLicense(license: ILicense) { //деактивировать
    ElMessageBox.confirm(
      `Вы действительно хотите деактивировать лицензию "${license.name}"?
      После совершения данного действия лицензия не подлежит восстановлению.`,
      "Деактивация лицензии",
      {
        confirmButtonText: "Деактивировать",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--danger",
        type: "error",
      }
    ).then(async () => {
      await LicenseStore.deactivateLicense(license.licenseId) 
      this.getLicenseList()
    })
  }

  async updateLicense(body: ILicense) {
    await LicenseStore.updateLicense({
      licenseId: this.selectedLicense.id,
      body: body
    });
  }

  async updateBalance() {
    await FinanceStore.getBalance();
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getLicenseList();
  }

  amountDays(date: string){
    if (!date) return 0;
    return moment(date).diff(moment(), 'days')
  }

  async changeSort(e: {
    column: string;
    prop: keyof ILicenseListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";
    const sortMap: {
      [key: string]: keyof ILicenseListSort
    } = {
      activeTo: "activeTo",
      name: "name"
    };
    if (!sortMap[e.prop]) return;
    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }
    this.getLicenseList();
  }

  get licenseList() {
    return LicenseStore.licenseList;
  }

  get dictionary() {
    return RootStore.dictionaries;
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getLicenseList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getLicenseList();
  }
}
