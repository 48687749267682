
import { Options, Vue } from 'vue-class-component';
import NotificationStore from "@/store/notification";
import { INotification } from '@/store/notification/interfaces';
import { Watch } from 'vue-property-decorator';
import {
    RefreshRight,
} from '@element-plus/icons';
import router from '@/router';

const defaultParams = JSON.stringify({
  list: [] as INotification[], 
  page: 1,      
  limit: 10,       
  more: true  
});

@Options({
  components: {RefreshRight},
})
export default class Bell extends Vue {
  onlyUnread = false
  openView = false
  activeNames = ["unread", "read"]
  selectedNotification = {} as INotification
  dialogVisible = false

  unread = JSON.parse(defaultParams);
  read = JSON.parse(defaultParams);

  @Watch('openView')
  async onOpenChanged() {
    if(!this.openView) {
      this.closeViewDialog()

      const notificationIds = this.unread.list
          .filter((notification: INotification) => notification.isRead == true)
          .map((notification: INotification) => notification.externId)
      if(notificationIds.length) {
        await NotificationStore.notificationsRead({
          notificationIds: notificationIds
        })  
        this.init()
        return
      }
    }
    else {
      this.init()
      if(this.unread.list.length >= this.notificationsListUnread.total)
        this.unread.list = this.notificationsListUnread.data

      if(this.read.list.length >= this.notificationsListRead.total)
        this.read.list = this.notificationsListRead.data  
    }
  }

  mounted() { 
    this.monitorHover()
    this.init()
  }

  async init() {
    this.clearFilter()
    this.mutationDOM()
    await this.getNotificationsUnread()
    await this.getNotificationsRead()
    this.setLoadingObserver()
  }

  monitorHover() {
    let bell = document.getElementById('bell') as HTMLElement;
    let notificationContainer = document.getElementById('notification-container') as HTMLElement;

    bell.onmouseenter = () => { 
      this.openView = true
    }
    notificationContainer.onmouseenter = () => { 
      this.openView = true
    }
    notificationContainer.onmouseleave = () => {
      this.openView = false 
    }
  }

  async getNotificationsUnread() {
    await NotificationStore.getNotificationList({
      page: this.unread.page,
      limit: this.unread.limit,
      isRead: false
    })

    
    if (this.unread.list.length < this.notificationsListUnread.total) {
      this.unread.list.push(...this.notificationsListUnread.data)
      this.unread.page++
    }
    
    if (this.unread.list.length >= this.notificationsListUnread.total) 
      this.unread.more = false 
  }

  async getNotificationsRead() {
    await NotificationStore.getNotificationList({
      page: this.read.page,
      limit: this.read.limit,
      isRead: true
    })

    if (this.read.list.length < this.notificationsListRead.total) {
      this.read.list.push(...this.notificationsListRead.data)
      this.read.page++
    }

    if (this.read.list.length >= this.notificationsListRead.total) 
      this.read.more = false
  }

  
  setLoadingObserver() {
    const loadingUnreadObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => { 
        if (entry.isIntersecting) { 
          if (this.unread.list.length >= this.notificationsListUnread.total) { 
            this.unread.more = false
            return
          } 
          this.getNotificationsUnread()
        }
      })
    })

    const loadingReadObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => { 
        if (entry.isIntersecting) { 
          if (this.read.list.length >= this.notificationsListRead.total) { 
            this.read.more = false
            return
          }
          this.getNotificationsRead() 
        }
      })
    })

    loadingUnreadObserver.observe(document.querySelector('#loadUnread') as Element)
    loadingReadObserver.observe(document.querySelector('#loadRead') as Element)
  }

  mutationDOM() {
    const notificationsObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) { 
          entry.target.classList.add('_active') 
          observer.unobserve(entry.target); 
        }
      })
    });

    const mutationTimelineObserver = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          document.querySelectorAll('.notification-block:not(._active)').forEach(notification => {
            notificationsObserver.observe(notification)
          })
          return
        }
      }
    })

    mutationTimelineObserver.observe(
      document.querySelector('#unreadTimeline') as Element, 
      { childList: true }
    )
    mutationTimelineObserver.observe(
      document.querySelector('#readTimeline') as Element, 
      { childList: true }
    )
  }

  async readAll() {
    await NotificationStore.notificationsRead({
      notificationIds: this.unread.list.map((notification: INotification) => notification.externId)
    })  
    this.init()
  }

  viewNotification(notification: INotification) {
    this.selectedNotification = notification  
    this.dialogVisible = true
  }

  closeViewDialog() {
    this.dialogVisible = false
    setTimeout(async () => {
      this.selectedNotification = {} as INotification
    }, 500);
  }

  redirectToPay() {
    router.push('/payment')
  }

  get notificationsListRead() {
    return NotificationStore.notificationsListRead;
  }

  get notificationsListUnread() {
    return NotificationStore.notificationsListUnread;
  }

  clearFilter() {
    this.unread = JSON.parse(defaultParams)
    this.read = JSON.parse(defaultParams)
  }

}
