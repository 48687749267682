import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackendBilling } from "@/plugins/axios";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISort } from "@/interfaces";
import { IBill, IBillFile, IBillsList } from "@/store/bill/interfaces";

@Module({ dynamic: true, store, name: "Bill" })
class BillStore extends VuexModule {
  private _billList = {} as IBillsList;
  private _billFile = {} as IBillFile;

  @Mutation
  public SET_BILLS_LIST(value: IBillsList): void {
    this._billList = value;
  }

  @Mutation
  public SET_BILLS_FILE(value: IBillFile): void {
    this._billFile = value;
  }

  @Action({ rawError: true })
  public async getBillFile(options: {
    id: string
    params: {
      base64: number
    }
  }) {
    const response: AxiosResponse<IBillFile> = await instanceBackendBilling.get(
      `bills/${options.id}/file`, { params: options.params }
    );
    
    this.SET_BILLS_FILE(response.data);
    return response.data; 
  }

  @Action({ rawError: true })
  public async createBill(amount: number) {
    const response = await instanceBackendBilling.post(
      "bills", { amount: amount }
    );

    return response || "error"
  }

  @Action({ rawError: true })
  public async getBillList(options?: IPagination & ISort) {
    const response: AxiosResponse<IBillsList> = await instanceBackendBilling.get(
      "bills", { params: options }
    );
    this.SET_BILLS_LIST(response.data);
  }

  get billList() {
    return this._billList;
  }

  get billFile() {
    return this._billFile;
  }
}

export default getModule(BillStore);
